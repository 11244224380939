import * as S from './StickyCta.styles'
import React from 'react'

export default function StickyCta({
  show = true,
  animate = false,
  onClick,
  text,
}: {
  show?: boolean
  animate?: boolean
  onClick: () => void
  text: string
}) {
  return (
    <S.StickyContainer
      exit={{ translateY: 75 }}
      initial={{ translateY: animate ? 75 : 0 }}
      animate={{
        translateY: show ? 0 : 75,
      }}
      transition={{
        translateY: {
          duration: 0.2,
        },
      }}
    >
      <S.LetsGoButton onClick={onClick} data-testid="cta-homepage-lets-go">
        {text}
      </S.LetsGoButton>
    </S.StickyContainer>
  )
}
