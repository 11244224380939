import { MIXED_SIZES } from '../../pages/PhotoStyler/TileDesignerConsts'
import type { TFunction } from 'i18next'
import { getSizesData } from './getSizesData'

// This file includes SSR runnable code
export function getSizeName({
  size,
  t,
  pricingCountry,
}: {
  size: string
  t: TFunction
  pricingCountry: string
}) {
  if (size === MIXED_SIZES) {
    return t('order.styles.mixed_styles')
  } else {
    const isSizesInInches = ['us', 'ca'].includes(pricingCountry.toLowerCase())
    if (isSizesInInches) {
      return getSizesData()[size]?.longDisplayName
    }
    return getSizesData()[size]?.longDisplayNameCms
  }
}
