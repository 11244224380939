import { styled } from 'styled-components'
import { m } from 'framer-motion'
import { type CSSProperties } from 'react'

export const Container = styled(m.div)`
  width: 100%;
  position: relative;
  min-height: 100px;
`

export const VideoHydrateRoot = styled.div`
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: 50%;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: 50%;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  object-fit: cover;
`

type VideoProps = {
  $fit?: 'cover' | 'contain' | 'fill'
  $position: CSSProperties['objectPosition']
}

export const ChildrenContainer = styled.div`
  position: absolute;
  z-index: 2;
  inset: 0;
`

export const Poster = styled.img<VideoProps>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $fit }) => $fit || 'cover'};
  object-position: ${({ $position = 'unset' }) => $position};
  z-index: 1;
  background-position: 50%;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
`
