import { logger } from '../services/logger'
import { extractErrorMessage } from './ApiUtils'

export function getVideoPercentagePlayed(video: HTMLVideoElement) {
  return (video.currentTime / video.duration) * 100
}

export function attemptPlayVideo(
  video: HTMLVideoElement,
  source: string
): Promise<void> | undefined {
  if (!video) return

  const playPromise = video.play()
  // Safari playback bug
  // Based on https://github.com/aws/amazon-chime-sdk-js/pull/2391/files#diff-5dbdb71553bcb184ab921202e679701b0a57cf378ea628d6bd23c44023e911f4R66
  if (playPromise) {
    playPromise.catch((error) => {
      logger.warning('Error playing video', {
        error: extractErrorMessage(error),
        source,
      })
    })
  }
  return playPromise
}
