import { styled } from 'styled-components'
import { ReactComponent as TrustPilotLogoSvg } from '../../../../images/homePage/trustpilot-logo.svg'
import { onMediumScreen } from 'mixtiles-web-common'
import { ReactComponent as ReviewStars } from '../../../../images/homePage/review-stars.svg'

export const Container = styled.div`
  padding: 48px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${onMediumScreen()} {
    padding-top: 0;
    margin-right: 12px;
  }
`

export const ContentContainer = styled.div`
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;
  ${onMediumScreen()} {
    flex-direction: row;
    padding-bottom: 0;
    gap: 183px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Title = styled.h2`
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  ${onMediumScreen()} {
    display: none;
  }
`

export const ReviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 500;
  ${onMediumScreen()} {
    flex-direction: column;
    gap: 26.25px;
    align-items: start;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  white-space: nowrap;
  ${onMediumScreen()} {
    flex-direction: column;
    gap: 8px;
    align-items: start;
    font-size: 19px;
    font-weight: 400;
    line-height: 81.7%;
  }
`

export const TrustPilotLogo = styled(TrustPilotLogoSvg)`
  transform: translateY(-3px);
`

export const VSeparator = styled.div`
  width: 1px;
  height: 9px;
  opacity: 0.2;
  background: ${({ theme }) => theme.colors.black};
  ${onMediumScreen()} {
    display: none;
  }
`

export const Separator = styled.div`
  background: ${({ theme }) => theme.colors.grayMedium};
  height: 1px;
  width: 100%;
  ${onMediumScreen()} {
    display: none;
  }
`

export const IllustrationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  text-transform: capitalize;

  ${onMediumScreen()} {
    gap: 52px;
  }
`

export const IllustrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 18px;
  font-weight: 500;
  ${onMediumScreen()} {
    justify-content: space-between;
    font-size: 17px;
    line-height: 30.414px; /* 178.904% */
    gap: 15px;
    font-weight: 400;
  }
`

export const ReviewScore = styled.span`
  ${onMediumScreen()} {
    display: none;
  }
`

export const ReviewTitle = styled.span`
  height: 23px;
  width: 315px;
`

export const BoldReviewTitle = styled(ReviewTitle)`
  font-weight: 700;
`

export const ReviewStarsSvg = styled(ReviewStars)`
  width: 85px;
  height: 17px;
  ${onMediumScreen()} {
    width: 116px;
    height: 21.75px;
  }
`

export const SupportImage = styled.img`
  width: 98px;
  height: 78px;
  ${onMediumScreen()} {
    width: 102px;
    height: 80px;
  }
`

export const ShippingImage = styled.img`
  width: 110px;
  height: 89px;
  ${onMediumScreen()} {
    width: 110px;
    height: 86px;
  }
`

export const RatedImage = styled.img`
  width: 100px;
  height: 100px;
  ${onMediumScreen()} {
    width: 96px;
    height: 96px;
  }
`
