import * as S from './FaqSection.styles'
import { useState } from 'react'
import { analytics, EVENT_NAMES } from 'services/Analytics/Analytics'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import classnames from 'classnames'
import { useProductType } from '../../services/ProductTypesManager'
import useScreenSize from '../../hooks/useScreenSize'
import { PRODUCT_TYPES } from '../../services/ProductTypeState'
import type { QuestionProps } from './consts'
import { useKeys } from 'services/KeysProvider'
import { useTranslation } from 'react-i18next'
import { getSizeName } from 'services/SizesManager/SizesManagerHelper'
import { getPricingCountry } from 'services/CountryManager'

function ExpandIcon({ expanded }: { expanded: boolean }) {
  return (
    <div className="wrap">
      <span className={classnames('arrow', !expanded && 'active')}>
        <span />
        <span />
      </span>
    </div>
  )
}

export default function FaqSection({
  faqs,
  source,
}: {
  faqs: QuestionProps[]
  source: string
}) {
  const keys = useKeys()
  const pricingCountry = getPricingCountry(keys.ipCountry)
  const { t } = useTranslation()
  const { productType } = useProductType()
  const isMediumScreen = useScreenSize('md')
  const [expandedId, setExpandedId] = useState<string | null>(null)

  const translatedFaqs = faqs.map((faq) => ({
    ...faq,
    translateParams: Object.entries(faq.translateParams || {}).map(
      ([key, size]) => [key, getSizeName({ size, t, pricingCountry })]
    ),
  }))

  const handleFaqViewed = () => {
    analytics.track(
      productType === PRODUCT_TYPES.PHOTO_BOOK
        ? EVENT_NAMES.BOOK_INTRO_HOMEPAGE_FAQ_VIEWED
        : EVENT_NAMES.HOMEPAGE_FAQ_VIEWED,
      { Source: source }
    )
  }

  const handleFaqTapped = ({
    question,
    translateKey,
    isExpanded,
  }: {
    question: string
    translateKey: string
    isExpanded: boolean
  }) => {
    analytics.track(
      productType === PRODUCT_TYPES.PHOTO_BOOK
        ? EVENT_NAMES.BOOK_INTRO_HOMEPAGE_FAQ_TAPPED
        : EVENT_NAMES.HOMEPAGE_FAQ_TAPPED,
      {
        Question: question,
      }
    )
    setExpandedId(isExpanded ? translateKey : null)
  }

  return (
    <S.FaqVisibilityDetector onVisible={handleFaqViewed}>
      <S.Container>
        <S.TextContainer itemScope itemType="https://schema.org/FAQPage">
          <S.Title>
            {isMediumScreen ? t('general.menu.faq') : t('homepage.faq.title')}
          </S.Title>
          <S.QuestionsContainer id="faq-section">
            {translatedFaqs.map(
              ({ translateKey, question, translateParams = {} }) => (
                <S.QuestionListItem
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                  key={translateKey}
                  onClick={() =>
                    handleFaqTapped({
                      question,
                      translateKey,
                      isExpanded: expandedId !== translateKey,
                    })
                  }
                >
                  <Accordion
                    key={translateKey}
                    expanded={expandedId === translateKey}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandIcon expanded={expandedId === translateKey} />
                      }
                      itemProp="name"
                    >
                      {t(`homepage.faq.${translateKey}.title`)}
                    </AccordionSummary>
                    <AccordionDetails
                      itemScope
                      itemProp="acceptedAnswer"
                      itemType="https://schema.org/Answer"
                    >
                      <div itemProp="text">
                        {t(
                          `homepage.faq.${translateKey}.content`,
                          translateParams
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </S.QuestionListItem>
              )
            )}
          </S.QuestionsContainer>
        </S.TextContainer>
      </S.Container>
    </S.FaqVisibilityDetector>
  )
}
