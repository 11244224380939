import React, {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useEvent } from 'react-use-event-hook'

type PlaybackContextValue = {
  onIntersectionChange: (
    id: string,
    isIntersecting: boolean,
    percentage: number
  ) => void
  toggleMute: () => void
  muted: boolean
  currentPlayingId?: string
  onPlayingIdUnmount: (playingId: string) => void
}

const PlaybackContext = createContext<PlaybackContextValue | null>(null)

function getMaxId(records: Record<string, number>): string | undefined {
  let maxId: string | undefined
  let maxValue = Number.MIN_VALUE

  for (const [id, value] of Object.entries(records)) {
    if (value > maxValue) {
      maxValue = value
      maxId = id
    }
  }

  return maxId
}

export function PlaybackProvider({ children }: PropsWithChildren) {
  const [currentPlayingId, setCurrentPlayingId] = useState<string | undefined>()
  const [muted, setMuted] = useState(true)
  // Mapping video to intersection percentage
  const intersectionState = useRef<Record<string, number>>({})

  const onIntersectionChange = useEvent(
    (id: string, isIntersecting: boolean, percentage: number) => {
      if (!isIntersecting) {
        delete intersectionState.current[id]
        return
      }

      intersectionState.current[id] = percentage
    }
  )

  // Every 32ms we check which video has the highest intersection ratio to determine who plays
  useEffect(() => {
    const interval = setInterval(() => {
      const mostIntersectingId = getMaxId(intersectionState.current)

      // Won't cause re-render if id did not change
      setCurrentPlayingId(mostIntersectingId)
    }, 32)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const toggleMute = useCallback(() => {
    setMuted((muted) => !muted)
  }, [])

  // When a video unmounts we need to remove it from the intersection state
  const onPlayingIdUnmount = (playingId: string) => {
    if (playingId === currentPlayingId) {
      setCurrentPlayingId(undefined)
    }
    delete intersectionState.current[currentPlayingId!]
  }

  const value: PlaybackContextValue = useMemo(
    () => ({
      muted,
      onIntersectionChange,
      toggleMute,
      currentPlayingId,
      onPlayingIdUnmount,
    }),
    [muted, currentPlayingId, onIntersectionChange, toggleMute]
  )

  return (
    <PlaybackContext.Provider value={value}>
      {children}
    </PlaybackContext.Provider>
  )
}

export function usePlaybackData() {
  const data = useContext(PlaybackContext)
  if (!data) throw new Error('PlaybackContext empty')

  return data
}
