import * as S from './CoveredSection.styles'
import VisibilityDetector from '../../../../components/VisibilityDetector/VisibilityDetector'
import {
  analytics,
  EVENT_NAMES,
} from '../../../../services/Analytics/Analytics'
import useScreenSize from '../../../../hooks/useScreenSize'
import { useTranslation } from 'react-i18next'

const SHIPPING_URL =
  'https://res.cloudinary.com/mixtiles/image/upload/v1708506319/assets/illustrations/Photo_Books/Shipping.png'
const SUPPORT_URL =
  'https://res.cloudinary.com/mixtiles/image/upload/v1708506263/assets/illustrations/Photo_Books/Support.png'
const RATED_URL =
  'https://res.cloudinary.com/mixtiles/image/upload/v1708504947/assets/illustrations/Photo_Books/Rated.png'

export type CoveredSectionProps = {
  communicateFreeShipping: boolean
}

// Notice: the mobile version of this component is used by Mixtiles Homepage. If the UI of the photo books "covered" section
// diverges from the photos "covered" section, separate the two components
export default function CoveredSection({
  communicateFreeShipping,
}: CoveredSectionProps) {
  const { t } = useTranslation()
  const isMediumScreen = useScreenSize('md')
  const illustrations = isMediumScreen
    ? [
        {
          Illustration: S.ShippingImage,
          src: SHIPPING_URL,
          labelKey: communicateFreeShipping
            ? 'homepage.covered.shipping'
            : 'pitch.global_shipping',
        },
        {
          Illustration: S.SupportImage,
          src: SUPPORT_URL,
          labelKey: 'homepage.covered.support',
        },
        {
          Illustration: S.RatedImage,
          src: RATED_URL,
          labelKey: 'homepage.covered.trustpilot',
        },
      ]
    : [
        {
          Illustration: S.SupportImage,
          src: SUPPORT_URL,
          labelKey: 'homepage.covered.support',
        },
        {
          Illustration: S.ShippingImage,
          src: SHIPPING_URL,
          labelKey: communicateFreeShipping
            ? 'homepage.covered.shipping'
            : 'pitch.global_shipping',
        },
      ]

  return (
    <VisibilityDetector
      onVisible={() => {
        analytics.track(EVENT_NAMES.HOMEPAGE_COVERED_VIEWED, {
          Source: 'Homepage',
        })
      }}
    >
      <S.Container>
        <S.ContentContainer>
          {!isMediumScreen && (
            <S.TitleContainer>
              <S.Title>{t('homepage.covered.title')}</S.Title>
              <S.ReviewContainer>
                <S.ReviewStarsSvg />
                <S.ReviewScore> 4.7/5 </S.ReviewScore>
                <S.VSeparator />
                <S.TextWrapper>
                  {isMediumScreen ? (
                    <>
                      <S.ReviewTitle>
                        <S.BoldReviewTitle>
                          {t('homepage.rated.rated_excellent')}
                        </S.BoldReviewTitle>
                        {` ${t('homepage.rated.on')} `}
                        <S.BoldReviewTitle>Trustpilot</S.BoldReviewTitle>
                      </S.ReviewTitle>
                      <span>{t('homepage.rated.reviews')}</span>
                    </>
                  ) : (
                    <>
                      <S.TrustPilotLogo />, 20K+ {t('homepage.covered.reviews')}
                    </>
                  )}
                </S.TextWrapper>
              </S.ReviewContainer>
            </S.TitleContainer>
          )}
          <S.IllustrationsContainer>
            {illustrations.map(({ Illustration, src, labelKey }) => (
              <S.IllustrationContainer key={labelKey}>
                <Illustration src={src} />
                {t(labelKey)}
              </S.IllustrationContainer>
            ))}
          </S.IllustrationsContainer>
        </S.ContentContainer>
        <S.Separator />
      </S.Container>
    </VisibilityDetector>
  )
}
