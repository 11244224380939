import { styled } from 'styled-components'
import { onMediumScreen, onXXLargeScreen } from 'mixtiles-web-common'
import VisibilityDetector from '../VisibilityDetector/VisibilityDetector'

export const Container = styled.div`
  ${onMediumScreen()} {
    width: 100%;
    margin-bottom: 100px;
  }
`

export const Image = styled.img`
  display: none;
  ${onMediumScreen()} {
    display: block;
    padding: 120px 0;
  }
`

export const FaqVisibilityDetector = styled(VisibilityDetector)`
  width: 100%;
  display: grid;
  place-items: center;
`

export const TextContainer = styled.div`
  padding: 50px 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 366px;
  align-items: center;
  ${onMediumScreen()} {
    gap: 32px;
    padding: 0;
    max-width: 100%;
    align-items: start;
  }

  #faq-section {
    li > div {
      background: rgba(235, 231, 231, 0.6);
      box-shadow: none !important;
      border: 0;
      border-radius: 12px;
      color: ${({ theme }) => theme.colors.black};
      ${onMediumScreen()} {
        background: inherit;
        color: ${({ theme }) => theme.colors.secondaryDark};
        border-radius: 0;
      }
    }

    .MuiAccordionSummary-root {
      font-size: 16px;
      font-weight: 500;
      min-height: 58px;
      line-height: 140%;
      padding-left: 20px;
      padding-right: 12px;
      ${onMediumScreen()} {
        min-height: 0;
        padding-left: 0;
        font-size: 18px;
      }
    }
    .MuiAccordionSummary-content {
      margin: 12px 0;
      ${onMediumScreen()} {
        margin: 0;
      }
    }
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: none !important;
    }
    .MuiAccordionDetails-root {
      padding: 0 20px 20px 20px;
      line-height: 1.5;
    }

    .wrap {
      height: 20px;
    }
    .arrow {
      width: 4px;
      height: 1.25rem;
      display: inline-block;
      position: relative;
      margin: 0 1rem;
    }
    .arrow span {
      top: 0.5rem;
      position: absolute;
      width: 9px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.grayDark};
      display: inline-block;
      transition: all 0.2s ease;
      border-radius: 25px;
    }
    .arrow span:first-of-type {
      left: 0;
      transform: rotate(45deg);
    }
    .arrow span:last-of-type {
      right: 0;
      transform: rotate(-45deg);
    }
    .arrow.active span:first-of-type {
      transform: rotate(-45deg);
    }
    .arrow.active span:last-of-type {
      transform: rotate(45deg);
    }
    .arrow--l-r {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      position: relative;
      margin: 0 1rem;
    }
    .arrow--l-r.left span:nth-child(4) {
      transform: rotate(-45deg);
    }
    .arrow--l-r.left span:nth-child(5) {
      transform: rotate(45deg);
    }
    .arrow--l-r.right span:nth-child(1) {
      transform: rotate(-45deg);
    }
    .arrow--l-r.right span:nth-child(2) {
      transform: rotate(45deg);
    }
    .arrow--l-r span {
      position: absolute;
      height: 0.1rem;
      background-color: #efefef;
      transition: transform 0.2s ease;
    }
    .arrow--l-r span:nth-child(1),
    .arrow--l-r span:nth-child(2),
    .arrow--l-r span:nth-child(4),
    .arrow--l-r span:nth-child(5) {
      width: 0.625rem;
      top: 0.625rem;
    }
    .arrow--l-r span:nth-child(1) {
      right: 0;
      transform-origin: bottom right;
    }
    .arrow--l-r span:nth-child(2) {
      right: 0;
      transform-origin: top right;
    }
    .arrow--l-r span:nth-child(3) {
      width: 1.25rem;
      top: 0.625rem;
    }
    .arrow--l-r span:nth-child(4) {
      left: 0;
      transform-origin: bottom left;
    }
    .arrow--l-r span:nth-child(5) {
      left: 0;
      transform-origin: top left;
    }
  }

  ${onMediumScreen()} {
    #faq-section {
      li > div {
        background: inherit;
        color: ${({ theme }) => theme.colors.secondaryDark};
      }

      .MuiAccordionSummary-root {
        min-height: 0;
        font-size: 28px;
        line-height: 48px;

        ${onXXLargeScreen()} {
          font-size: 35px;
        }
      }
      .MuiAccordionSummary-content {
        margin-bottom: 0;
        line-height: 140%;
      }
      .MuiAccordionDetails-root {
        font-size: 20px;
        line-height: 32px;
        max-width: min(95%, 800px);
        padding: 12px 0 0 0;

        ${onXXLargeScreen()} {
          font-size: 25px;
          line-height: 40px;
          max-width: min(95%, 1000px);
        }
      }

      .wrap {
        height: 40px;

        .arrow {
          transform: scale(1.2);
        }
      }
    }
  }
`

export const Title = styled.h2`
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;

  ${onMediumScreen()} {
    text-align: start;
    font-size: 42px;
  }

  ${onXXLargeScreen()} {
    font-size: 52px;
  }
`

export const QuestionsContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
  ${onMediumScreen()} {
    gap: 0;
    width: 100%;
  }
`
export const QuestionListItem = styled.li`
  ${onMediumScreen()} {
    cursor: pointer;
    padding-top: 40px;

    transition: padding-left 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.grayBackgroundWhite};
      padding-left: 20px;
    }

    &:not(:last-child)::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.grayBright};
      margin-top: 40px;
    }

    &:last-child {
      padding-bottom: 40px;
    }
  }
`
