import { type MutableRefObject, useEffect } from 'react'
import { useEvent } from 'react-use-event-hook'

// Trick to play videos on ios lower power mode
// Based on https://shaktisinghcheema.com/how-to-autoplay-video-on-mobile-devices-on-low-power-mode/
export default function useLowPowerPlayback({
  videoRef,
  shouldPlay,
  attemptPlay,
}: {
  videoRef?: MutableRefObject<HTMLVideoElement | null>
  shouldPlay: boolean
  attemptPlay: () => void
}) {
  const onTouch = useEvent(() => {
    if (!shouldPlay) {
      return
    }

    const video = videoRef?.current
    if (!video) return

    const isPlaying =
      video.currentTime > 0 && !video.paused && video.readyState > 2

    if (!isPlaying) {
      attemptPlay()
    }
  })

  useEffect(() => {
    document.body.addEventListener('click', onTouch)
    document.body.addEventListener('touchstart', onTouch)

    return () => {
      document.body.removeEventListener('click', onTouch)
      document.body.removeEventListener('touchstart', onTouch)
    }
  }, [])
}
